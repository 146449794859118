
	/* COLOR */
	$nds-color-active-primary: #d3a500;
	$nds-color-active-primary-inverse: #d3a500;
	$nds-color-border-elevation: #e6e2da;
	$nds-color-border-primary: #ccc6bb;
	$nds-color-border-primary-inverse: #ffffff;
	$nds-color-disabled-01: #b1aaa1;
	$nds-color-disabled-02: #ccc6bb;
	$nds-color-disabled-03: #7d766f;
	$nds-color-focus-primary: #0000ff;
	$nds-color-hover-primary: #7d766f;
	$nds-color-hover-primary-inverse: #ffffff;
	$nds-color-icon-accent: #947200;
	$nds-color-icon-inverse: #ffffff;
	$nds-color-icon-on-accent: #1e1b19;
	$nds-color-icon-on-active-primary: #ffffff;
	$nds-color-icon-on-primary: #ffffff;
	$nds-color-icon-on-primary-inverse: #1e1b19;
	$nds-color-icon-primary: #1e1b19;
	$nds-color-icon-secondary: #4c4641;
	$nds-color-icon-tertiary: #7d766f;
	$nds-color-input-border: #7d766f;
	$nds-color-input-border-hover: #4c4641;
	$nds-color-interactive-accent: #e4b200;
	$nds-color-interactive-accent-active: #c39800;
	$nds-color-interactive-accent-hover: #d3a500;
	$nds-color-interactive-border: #000000;
	$nds-color-interactive-border-active: #000000;
	$nds-color-interactive-border-hover: #000000;
	$nds-color-interactive-border-inverse: #ffffff;
	$nds-color-interactive-border-inverse-active: #ffffff;
	$nds-color-interactive-border-inverse-hover: #ffffff;
	$nds-color-interactive-danger: #c6342a;
	$nds-color-interactive-danger-active: #9b271f;
	$nds-color-interactive-danger-hover: #b02d24;
	$nds-color-interactive-primary: #1e1b19;
	$nds-color-interactive-primary-active: #4c4641;
	$nds-color-interactive-primary-hover: #34302c;
	$nds-color-interactive-primary-inverse: #f6f5f2;
	$nds-color-interactive-primary-inverse-active: #d9d4c9;
	$nds-color-interactive-primary-inverse-hover: #e6e2da;
	$nds-color-link-primary: #947200;
	$nds-color-link-primary-inverse: #b38b00;
	$nds-color-link-underline: #ccc6bb;
	$nds-color-link-visited: #947200;
	$nds-color-overlay-primary: #000000;
	$nds-color-pressed-primary: #7d766f;
	$nds-color-pressed-primary-inverse: #ffffff;
	$nds-color-selected-primary: #1e1b19;
	$nds-color-selected-primary-active: #4c4641;
	$nds-color-selected-primary-hover: #34302c;
	$nds-color-shadow-primary: #34302c;
	$nds-color-skeleton-element: #e6e2da;
	$nds-color-support-error: #c6342a;
	$nds-color-support-error-light: #fdeded;
	$nds-color-support-info: #0072a2;
	$nds-color-support-info-light: #e6f2ff;
	$nds-color-support-notification: #b02d24;
	$nds-color-support-success: #0e7a53;
	$nds-color-support-success-light: #d2fae4;
	$nds-color-support-warning: #9f591a;
	$nds-color-support-warning-light: #feeee1;
	$nds-color-surface-canvas: #f6f5f2;
	$nds-color-surface-layer: #ffffff;
	$nds-color-surface-primary-inverse: #1e1b19;
	$nds-color-surface-undercanvas: #e6e2da;
	$nds-color-text-inverse: #ffffff;
	$nds-color-text-on-accent: #1e1b19;
	$nds-color-text-on-active-primary: #ffffff;
	$nds-color-text-on-primary: #ffffff;
	$nds-color-text-on-primary-inverse: #1e1b19;
	$nds-color-text-placeholder: #7d766f;
	$nds-color-text-primary: #1e1b19;
	$nds-color-text-secondary: #4c4641;
	$nds-color-text-tertiary: #7d766f;
	$nds-palette-black-100: #000000;
	$nds-palette-blue-05: #e6f2ff;
	$nds-palette-blue-10: #cce5ff;
	$nds-palette-blue-15: #afd9ff;
	$nds-palette-blue-20: #8fcdff;
	$nds-palette-blue-25: #66c1ff;
	$nds-palette-blue-30: #16b6ff;
	$nds-palette-blue-35: #00a8ed;
	$nds-palette-blue-40: #009ada;
	$nds-palette-blue-45: #008cc7;
	$nds-palette-blue-50: #007fb4;
	$nds-palette-blue-55: #0072a2;
	$nds-palette-blue-60: #006495;
	$nds-palette-blue-65: #005787;
	$nds-palette-blue-70: #00497a;
	$nds-palette-blue-80: #00325a;
	$nds-palette-blue-90: #001c37;
	$nds-palette-blue-95: #001126;
	$nds-palette-brandbook-pantone-110-c-gold-32: #daaa00;
	$nds-palette-brandbook-pantone-179-c-red-49: #e03c31;
	$nds-palette-brandbook-pantone-2955-c-blue-77: #003865;
	$nds-palette-brandbook-pantone-715-c-orange-32: #f68d2e;
	$nds-palette-brandbook-pantone-7690-blue-53: #0076a8;
	$nds-palette-brandbook-pantone-7728-green-62: #006845;
	$nds-palette-brandbook-pantone-9043-cream-black-4: #f6f5f2;
	$nds-palette-brandbook-pantone-black-c-cream-black-83: #2d2926;
	$nds-palette-cream-black-04: #f6f5f2;
	$nds-palette-cream-black-05: #f2f1ec;
	$nds-palette-cream-black-10: #e6e2da;
	$nds-palette-cream-black-15: #d9d4c9;
	$nds-palette-cream-black-20: #ccc6bb;
	$nds-palette-cream-black-25: #beb8ae;
	$nds-palette-cream-black-30: #b1aaa1;
	$nds-palette-cream-black-35: #a39d94;
	$nds-palette-cream-black-40: #969087;
	$nds-palette-cream-black-45: #89837b;
	$nds-palette-cream-black-50: #7d766f;
	$nds-palette-cream-black-55: #706963;
	$nds-palette-cream-black-60: #645d57;
	$nds-palette-cream-black-65: #57514c;
	$nds-palette-cream-black-70: #4c4641;
	$nds-palette-cream-black-80: #34302c;
	$nds-palette-cream-black-90: #1e1b19;
	$nds-palette-cream-black-95: #13100f;
	$nds-palette-gold-05: #ffefd6;
	$nds-palette-gold-10: #ffdea4;
	$nds-palette-gold-15: #ffce59;
	$nds-palette-gold-20: #f5bf00;
	$nds-palette-gold-25: #e4b200;
	$nds-palette-gold-30: #d3a500;
	$nds-palette-gold-35: #c39800;
	$nds-palette-gold-40: #b38b00;
	$nds-palette-gold-45: #a37f00;
	$nds-palette-gold-50: #947200;
	$nds-palette-gold-55: #856600;
	$nds-palette-gold-60: #765b00;
	$nds-palette-gold-65: #674f00;
	$nds-palette-gold-70: #594400;
	$nds-palette-gold-80: #3e2e00;
	$nds-palette-gold-90: #251a00;
	$nds-palette-gold-95: #181000;
	$nds-palette-green-05: #d2fae4;
	$nds-palette-green-10: #92f6c5;
	$nds-palette-green-15: #78eab5;
	$nds-palette-green-20: #69dba7;
	$nds-palette-green-25: #5bcd9a;
	$nds-palette-green-30: #4ebf8d;
	$nds-palette-green-35: #41b181;
	$nds-palette-green-40: #34a375;
	$nds-palette-green-45: #289569;
	$nds-palette-green-50: #1c875e;
	$nds-palette-green-55: #0e7a53;
	$nds-palette-green-60: #006c48;
	$nds-palette-green-65: #005f3f;
	$nds-palette-green-70: #005235;
	$nds-palette-green-80: #003823;
	$nds-palette-green-90: #002113;
	$nds-palette-green-95: #00150a;
	$nds-palette-orange-05: #feeee1;
	$nds-palette-orange-10: #fdddc3;
	$nds-palette-orange-15: #fccba5;
	$nds-palette-orange-20: #fbb885;
	$nds-palette-orange-25: #fba563;
	$nds-palette-orange-30: #fa9134;
	$nds-palette-orange-35: #e8852b;
	$nds-palette-orange-40: #d57a26;
	$nds-palette-orange-45: #c36e22;
	$nds-palette-orange-50: #b0641e;
	$nds-palette-orange-55: #9f591a;
	$nds-palette-orange-60: #8d4f16;
	$nds-palette-orange-65: #7c4412;
	$nds-palette-orange-70: #6b3a0e;
	$nds-palette-orange-80: #4b2707;
	$nds-palette-orange-90: #2d1503;
	$nds-palette-orange-95: #1e0c01;
	$nds-palette-red-05: #fdeded;
	$nds-palette-red-10: #fadbdb;
	$nds-palette-red-15: #f8c9c8;
	$nds-palette-red-20: #f6b7b5;
	$nds-palette-red-25: #f5a3a1;
	$nds-palette-red-30: #f38f8c;
	$nds-palette-red-35: #f27a75;
	$nds-palette-red-40: #f1615b;
	$nds-palette-red-45: #f14338;
	$nds-palette-red-50: #db3b30;
	$nds-palette-red-55: #c6342a;
	$nds-palette-red-60: #b02d24;
	$nds-palette-red-65: #9b271f;
	$nds-palette-red-70: #872019;
	$nds-palette-red-80: #5f140f;
	$nds-palette-red-90: #3b0906;
	$nds-palette-red-95: #280403;
	$nds-palette-white-0: #ffffff;

