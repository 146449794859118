@import './themes';
@import './custom/tokens';

@mixin nb-overrides() {
    nb-select.size-medium button {
        padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

        nb-icon {
            right: 0.41rem !important;
        }
    }

    nb-card nb-list {
        @include nb-scrollbars(
            nb-theme(card-scrollbar-color),
            nb-theme(card-scrollbar-background-color),
            nb-theme(card-scrollbar-width)
        );
    }

    .table {
        color: nb-theme(text-basic-color) !important;
    }

    // uses for first row in table, by default row is selected
    ng2-smart-table {
        tbody tr {
            &.selected {
                background: white !important;
            }
        }
    }

    nb-card-header {
        background: #EFEEE9 !important;
    }

    nb-sidebar.compacted nb-menu .menu-item a.active::before {
        background: nb-theme(menu-item-active-background-color) !important;
    }

    nb-sidebar.compacted {
        width: 6rem;

        .main-container {
            width: 6rem !important;

            > .scrollable {
                width: 6rem;
            }
        }

        & nb-menu {
            width: 6rem;
        }
    }

    nb-menu {
        background-color: nb-theme(menu-background-color);

        ul.menu-items {
            margin: 0;
            padding: 0;
        }

        .menu-group,
        .menu-item a {
            font-family: nb-theme(menu-text-font-family);
            font-size: nb-theme(menu-text-font-size);
            font-weight: nb-theme(menu-text-font-weight);
            line-height: nb-theme(menu-text-line-height);
            padding: nb-theme(menu-item-padding);
        }

        .menu-group,
        .menu-group nb-icon.menu-icon {
            color: nb-theme(menu-text-color);
        }

        .menu-item {
            a {
                color: nb-theme(menu-text-color);
                border-radius: nb-theme(menu-item-border-radius);
                border-radius: 4px;
            }

            a.active {
                background-color: nb-theme(menu-item-active-background-color);
                color: nb-theme(menu-item-active-text-color);
                border-radius: 4px;

                &:hover {
                    background-color: nb-theme(
                        menu-item-active-hover-background-color
                    );
                    color: nb-theme(menu-item-hover-text-color);
                    border-radius: 4px;

                    // > .menu-icon {
                    //   background-color: nb-theme(menu-item-active-hover-background-color);
                    //   color: nb-theme(menu-item-icon-active-color);
                    // }
                }

                .menu-icon {
                    color: nb-theme(menu-item-icon-active-color);
                }
            }

            a.active::before {
                background: nb-theme(
                    menu-item-active-hover-background-color
                ) !important;
            }

            a:hover {
                background-color: nb-theme(menu-item-hover-background-color);
                color: nb-theme(menu-item-hover-text-color);
                cursor: nb-theme(menu-item-hover-cursor);

                .menu-icon {
                    color: nb-theme(menu-item-icon-hover-color);
                }
            }

            .menu-icon {
                color: nb-theme(menu-item-icon-color);
                font-size: nb-theme(menu-item-icon-width);
                margin: nb-theme(menu-item-icon-margin);
                width: 1em;
                text-align: center;
            }

            .expand-state {
                color: nb-theme(menu-item-icon-color);
            }
        }

        // $divider: nb-theme(menu-item-divider-width) nb-theme(menu-item-divider-style) nb-theme(menu-item-divider-color);
        .menu-item {
            border-bottom: none;

            &:first-child {
                border-top: none;
            }
            &:last-child {
                border-bottom: none;
            }

            // .menu-item:first-child {
            //   border-top: $divider;
            // }
        }

        .menu-item > .menu-items {
            background-color: nb-theme(menu-submenu-background-color);
            margin: nb-theme(menu-submenu-margin);
            padding: nb-theme(menu-submenu-padding);
        }

        .menu-item > .menu-items > .menu-item {
            background: nb-theme(menu-submenu-background-color);
            color: nb-theme(menu-submenu-text-color);

            a {
                border: 1px solid $nds-color-surface-layer !important; // TODO
                padding: nb-theme(menu-submenu-item-padding);
            }

            a.active {
                background-color: nb-theme(
                    menu-submenu-item-active-background-color
                );
                border-color: nb-theme(menu-submenu-item-active-border-color);
                color: nb-theme(menu-submenu-item-active-text-color);

                .menu-icon {
                    color: nb-theme(menu-submenu-item-icon-active-color);
                }
            }

            a:hover {
                background-color: nb-theme(
                    menu-submenu-item-hover-background-color
                );
                border-color: nb-theme(menu-submenu-item-hover-border-color);
                color: nb-theme(menu-submenu-item-hover-text-color);

                .menu-icon {
                    color: nb-theme(menu-submenu-item-icon-hover-color);
                }
            }

            a.active:hover {
                background-color: nb-theme(
                    menu-submenu-item-active-hover-background-color
                );
                border-color: nb-theme(
                    menu-submenu-item-active-hover-border-color
                );
                color: nb-theme(menu-item-hover-text-color);
            }
        }

        .menu-item > .menu-items > .menu-group {
            &,
            & nb-icon.menu-icon {
                color: nb-theme(menu-group-text-color);
            }
        }
    }

    // nb-menu {
    //     background-color: $nds-color-selected-secondary-active;
    //     .menu-item {
    //         border-bottom: none;
    //         > a {
    //             color: $nds-palette-white-0;
    //             &:hover {
    //                 background-color: $nds-color-selected-secondary-active;
    //                 color: $nds-palette-white-0;
    //             }
    //             &:active {
    //                 background-color: $nds-color-selected-secondary-active;
    //                 color: $nds-palette-white-0;
    //             }
    //             &.selected {
    //                 background-color: $nds-color-selected-secondary-active;
    //                 color: $nds-palette-white-0;
    //             }
    //         }
    //     }
    // }

    .menu-sidebar {
        background-color: $nds-color-surface-layer !important;
        border: 1 solid $nds-color-surface-layer !important;
        // &:active {
        //     background-color: $nds-color-selected-secondary-active;
        // }
        // &.selected {
        //     background-color: $nds-color-selected-secondary-active;
        // }
    }
}
