.nds-typo-exp-display-l-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: 3.52px;
	text-transform: uppercase;
}

.nds-typo-exp-display-l-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 56px;
	line-height: 64px;
	letter-spacing: 4.48px;
	text-transform: uppercase;
}

.nds-typo-exp-display-l-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 64px;
	line-height: 72px;
	letter-spacing: 5.12px;
	text-transform: uppercase;
}

.nds-typo-exp-display-l-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 72px;
	line-height: 80px;
	letter-spacing: 5.76px;
	text-transform: uppercase;
}

.nds-typo-exp-display-m-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 2.56px;
	text-transform: uppercase;
}

.nds-typo-exp-display-m-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: 2.88px;
	text-transform: uppercase;
}

.nds-typo-exp-display-m-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: 3.52px;
	text-transform: uppercase;
}

.nds-typo-exp-display-m-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: 3.84px;
	text-transform: uppercase;
}

.nds-typo-exp-display-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 1.92px;
	text-transform: uppercase;
}

.nds-typo-exp-display-xl-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 64px;
	line-height: 72px;
	letter-spacing: 5.12px;
	text-transform: uppercase;
}

.nds-typo-exp-display-xl-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 80px;
	line-height: 88px;
	letter-spacing: 6.4px;
	text-transform: uppercase;
}

.nds-typo-exp-display-xl-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 104px;
	line-height: 120px;
	letter-spacing: 8.32px;
	text-transform: uppercase;
}

.nds-typo-exp-display-xl-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 120px;
	line-height: 136px;
	letter-spacing: 9.6px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-2-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 1.28px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-3-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1.12px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-l-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 2.56px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-l-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: 2.88px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-l-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: 3.52px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-l-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: 3.84px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-m-rs-1-2-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 28px;
	line-height: 36px;
	letter-spacing: 2.24px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-m-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: 2.56px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 1.92px;
	text-transform: uppercase;
}

.nds-typo-exp-headline-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 1.44px;
	text-transform: uppercase;
}

.nds-typo-exp-label-l {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 1.44px;
	text-transform: uppercase;
}

.nds-typo-exp-label-m {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 1.28px;
	text-transform: uppercase;
}

.nds-typo-exp-label-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.12px;
	text-transform: uppercase;
}

.nds-typo-exp-label-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.96px;
	text-transform: uppercase;
}

.nds-typo-pro-body-l {
	font-family: Brown-Light;
	font-weight: 300;
	font-size: 18px;
	line-height: 26px;
}

.nds-typo-pro-body-m {
	font-family: Brown-Light;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
}

.nds-typo-pro-body-s {
	font-family: Brown-Light;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
}

.nds-typo-pro-body-strong-l {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
}

.nds-typo-pro-body-strong-m {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.nds-typo-pro-body-strong-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.nds-typo-pro-display-l-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: -0.88px;
}

.nds-typo-pro-display-l-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 56px;
	line-height: 64px;
	letter-spacing: -1.12px;
}

.nds-typo-pro-display-l-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 64px;
	line-height: 72px;
	letter-spacing: -1.28px;
}

.nds-typo-pro-display-l-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 72px;
	line-height: 80px;
	letter-spacing: -1.44px;
}

.nds-typo-pro-display-m-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.64px;
}

.nds-typo-pro-display-m-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: -0.72px;
}

.nds-typo-pro-display-m-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: -0.88px;
}

.nds-typo-pro-display-m-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: -0.96px;
}

.nds-typo-pro-display-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

.nds-typo-pro-display-xl-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 64px;
	line-height: 72px;
	letter-spacing: -1.28px;
}

.nds-typo-pro-display-xl-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 80px;
	line-height: 88px;
	letter-spacing: -1.6px;
}

.nds-typo-pro-display-xl-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 104px;
	line-height: 120px;
	letter-spacing: -2.08px;
}

.nds-typo-pro-display-xl-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 120px;
	line-height: 136px;
	letter-spacing: -2.4px;
}

.nds-typo-pro-headline-2-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.nds-typo-pro-headline-3-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.nds-typo-pro-headline-l-rs-1 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.64px;
}

.nds-typo-pro-headline-l-rs-2 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: -0.72px;
}

.nds-typo-pro-headline-l-rs-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 44px;
	line-height: 52px;
	letter-spacing: -0.88px;
}

.nds-typo-pro-headline-l-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 48px;
	line-height: 56px;
	letter-spacing: -0.96px;
}

.nds-typo-pro-headline-m-rs-1-2-3 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 28px;
	line-height: 36px;
	letter-spacing: -0.56px;
}

.nds-typo-pro-headline-m-rs-4 {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.64px;
}

.nds-typo-pro-headline-s {
	font-family: Brown-Regular !important;
	font-weight: 400 !important;
	font-size: 24px !important;
	line-height: 32px !important;
}

.nds-typo-pro-headline-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
}

.nds-typo-pro-label-l {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
}

.nds-typo-pro-label-m {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: -0.32px;
}

.nds-typo-pro-label-s {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.nds-typo-pro-label-xs {
	font-family: Brown-Regular;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
}
