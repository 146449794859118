@use 'sass:map';

/* RADII tokens - This is NOT generated via specify */

$border-radius: (
    S: 4px,
    M: 8px,
    L: 16px,
    XL: 32px,
    MAX: 999px,
);

$border-radius-s: map.get($border-radius, S);
$border-radius-m: map.get($border-radius, M);
$border-radius-l: map.get($border-radius, L);
$border-radius-xl: map.get($border-radius, XL);
$border-radius-max: map.get($border-radius, MAX);
